import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import ExperienceWonScreen from '../components/ExperienceWonScreen';

class SpaGameLogic {
  constructor(canvas, giftCard, onComplete) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
    this.giftCard = giftCard;
    this.onComplete = onComplete;
    this.options = {
      scents: [
        { 
          id: 'lavender', 
          name: 'Lavender', 
          description: 'A calming essence that soothes the mind',
          image: '/assets/spa/scents/lavender.png',  // Calming purple lavender field
          particleColor: '#9F7AEA'
        },
        { 
          id: 'eucalyptus', 
          name: 'Eucalyptus', 
          description: 'Refreshing and invigorating',
          image: '/assets/spa/scents/eucalyptus.png',  // Fresh green eucalyptus leaves
          particleColor: '#48BB78'
        },
        { 
          id: 'vanilla', 
          name: 'Vanilla', 
          description: 'Sweet and comforting',
          image: '/assets/spa/scents/vanilla.png',  // Warm vanilla pods
          particleColor: '#ED8936'
        }
      ],
      treatments: [
        { 
          id: 'massage', 
          name: 'Massage', 
          description: 'Melt away tension',
          image: '/assets/spa/treatments/massage.png',  // Calming massage scene
          particleColor: '#F687B3'
        },
        { 
          id: 'facial', 
          name: 'Facial', 
          description: 'Rejuvenate your skin',
          image: '/assets/spa/treatments/facial.png',  // Facial treatment
          particleColor: '#63B3ED'
        },
        { 
          id: 'manicure', 
          name: 'Manicure', 
          description: 'Perfect pampering for your hands',
          image: '/assets/spa/treatments/manicure.png',  // Elegant manicure
          particleColor: '#F6AD55'
        }
      ],
      drinks: [
        { 
          id: 'tea', 
          name: 'Herbal Tea', 
          description: 'Soothing warmth in every sip',
          image: '/assets/spa/drinks/tea.png',  // Steaming herbal tea
          particleColor: '#68D391'
        },
        { 
          id: 'water', 
          name: 'Infused Water', 
          description: 'Refreshing and pure',
          image: '/assets/spa/drinks/water.png',  // Crystal clear water with fruits
          particleColor: '#4FD1C5'
        },
        { 
          id: 'champagne', 
          name: 'Champagne', 
          description: 'Celebrate in style',
          image: '/assets/spa/drinks/champagne.png',  // Elegant champagne glass
          particleColor: '#F6E05E'
        }
      ]
    };
    this.initializeState();
    
    // Add mouse position tracking
    this.mouseX = 0;
    this.mouseY = 0;
    
    // Bind mouse move handler
    this.handleMouseMove = this.handleMouseMove.bind(this);
    canvas.addEventListener('mousemove', this.handleMouseMove);
    
    // Add mobile detection and touch handling
    this.isMobile = window.innerWidth < 768;
    this.touchStart = null;
    this.currentCardOffset = 0;
    this.targetCardOffset = 0;
    
    // Bind touch handlers
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    
    // Add touch event listeners
    canvas.addEventListener('touchstart', this.handleTouchStart);
    canvas.addEventListener('touchmove', this.handleTouchMove);
    canvas.addEventListener('touchend', this.handleTouchEnd);
    
    this.countdownStarted = false;
    this.countdownStartTime = null;
    
    // Set canvas to fill window
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    
    // Add resize listener to keep canvas full screen
    window.addEventListener('resize', () => {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
      
      // Update mobile card dimensions on resize
      this.mobileCardWidth = window.innerWidth * 0.85;
      this.mobileCardHeight = this.mobileCardWidth * 1.4;
      this.mobileCardSpacing = window.innerWidth * 0.1;
    });
    
    this.currentStage = 0;
    this.choices = {
      scent: null,
      treatment: null,
      drink: null
    };
    
    // Initialize other properties
    this.selectedOption = null;
    this.transitionProgress = 0;
    this.visualizationProgress = 0;
    
    // Load assets
    this.loadAssets().then(() => {
      this.assetsLoaded = true;
    });
    
    // Simplified swipe handling
    this.touchStartX = 0;
    this.currentX = 0;
    this.isDragging = false;
    this.currentCardIndex = 0;
    this.mobileCardWidth = window.innerWidth * 0.75;
    this.mobileCardHeight = this.mobileCardWidth * 1.4;
    this.mobileCardSpacing = window.innerWidth * 0.12;
  }

  handleMouseMove(event) {
    const rect = this.canvas.getBoundingClientRect();
    this.mouseX = event.clientX - rect.left;
    this.mouseY = event.clientY - rect.top;
  }

  isHovered(x, y) {
    const hoverRadius = 100; // Adjust this value to change hover sensitivity
    const distance = Math.sqrt(
      Math.pow(this.mouseX - x, 2) + 
      Math.pow(this.mouseY - y, 2)
    );
    return distance < hoverRadius;
  }

  initializeState() {
    this.gameState = 'playing';
    this.assetsLoaded = false;
    this.currentStage = 0;
    this.choices = {
      scent: null,
      treatment: null,
      drink: null
    };
    this.transitionProgress = 0;
  }

  restart() {
    this.initializeState();
    this.init();
    this.startGameLoop();
  }

  async init() {
    console.log('Initializing game...');
    try {
      await this.loadAssets();
      this.verifyImages();
      this.initGameVariables();
      this.resizeCanvas();
      window.addEventListener('resize', () => this.resizeCanvas());
      this.startGameLoop();
      this.draw();
    } catch (error) {
      console.error('Error during initialization:', error);
    }
  }

  resizeCanvas() {
    if (this.canvas) {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
      console.log(`Canvas size: ${this.canvas.width}x${this.canvas.height}`);
    }
  }

  async loadAssets() {
    console.log('Starting asset loading...');
    
    try {
      // Load background first
      this.images = {
        background: await this.loadImage(new Image(), '/assets/spa/background.png')
      };
      
      // Load option images
      const optionImages = {
        lavender: '/assets/spa/scents/lavender.png',
        eucalyptus: '/assets/spa/scents/eucalyptus.png',
        vanilla: '/assets/spa/scents/vanilla.png',
        massage: '/assets/spa/treatments/massage.png',
        facial: '/assets/spa/treatments/facial.png',
        manicure: '/assets/spa/treatments/manicure.png',
        tea: '/assets/spa/drinks/tea.png',
        water: '/assets/spa/drinks/water.png',
        champagne: '/assets/spa/drinks/champagne.png'
      };

      // Load each option image
      for (const [key, src] of Object.entries(optionImages)) {
        try {
          this.images[key] = await this.loadImage(new Image(), src);
          console.log(`Loaded ${key} image`);
        } catch (error) {
          console.warn(`Failed to load ${key} image:`, error);
        }
      }

      console.log('All assets loaded:', this.images);
      this.assetsLoaded = true;
    } catch (error) {
      console.error('Error loading assets:', error);
      // Set up fallback colors
      this.assetsLoaded = true;
    }
  }

  loadImage(img, src) {
    return new Promise((resolve, reject) => {
      console.log(`Loading image from: ${src}`);
      
      // Reset the image object
      img = new Image();
      
      img.onload = () => {
        console.log(`Successfully loaded: ${src}`);
        resolve(img);
      };
      
      img.onerror = (error) => {
        console.error(`Error loading image: ${src}`, error);
        reject(error);
      };
      
      // Set crossOrigin to anonymous for CORS
      img.crossOrigin = 'anonymous';
      img.src = src;
    });
  }

  initGameVariables() {
    this.currentStage = 0;
    this.selectedOption = null;
    this.transitionProgress = 0;
  }

  update() {
    if (this.gameState !== 'playing' || !this.assetsLoaded) return;

    if (this.transitionProgress < 1) {
      this.transitionProgress += 0.02;
    }

    if (this.choices.scent && this.choices.treatment && this.choices.drink) {
      this.gameState = 'won';
      this.onComplete('won');
    }
  }

  draw() {
    if (!this.assetsLoaded) return;

    if (this.gameState === 'visualizing') {
      this.drawExperienceVisualization();
    } else if (this.gameState === 'playing') {
      // Normal game drawing
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      
      // Draw background
      if (this.images?.background?.complete) {
        try {
          this.ctx.drawImage(
            this.images.background,
            0,
            0,
            this.canvas.width,
            this.canvas.height
          );
        } catch (error) {
          console.error('Error drawing background:', error);
        }
      }
      
      // Draw current stage options
      this.drawCurrentStage();
    }
  }

  drawCurrentStage() {
    const options = this.getCurrentStageOptions();
    
    // Clear and draw background
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    if (this.images?.background?.complete) {
      try {
        const bgRatio = this.images.background.width / this.images.background.height;
        const canvasRatio = this.canvas.width / this.canvas.height;
        let drawWidth, drawHeight, drawX, drawY;
        
        if (bgRatio > canvasRatio) {
          drawHeight = this.canvas.height;
          drawWidth = drawHeight * bgRatio;
          drawX = (this.canvas.width - drawWidth) / 2;
          drawY = 0;
        } else {
          drawWidth = this.canvas.width;
          drawHeight = drawWidth / bgRatio;
          drawX = 0;
          drawY = (this.canvas.height - drawHeight) / 2;
        }
        
        this.ctx.drawImage(
          this.images.background,
          drawX,
          drawY,
          drawWidth,
          drawHeight
        );
      } catch (error) {
        console.error('Error drawing background:', error);
        // Fallback background color if image fails
        this.ctx.fillStyle = '#f0f0f0';
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
      }
    }
    
    // Simpler, darker title
    this.ctx.save();
    this.ctx.textAlign = 'center';
    this.ctx.fillStyle = '#1a1a1a';
    this.ctx.font = 'bold 32px "Helvetica Neue", Arial';
    this.ctx.fillText('Virtual Spa Experience', this.canvas.width/2, 60);
    this.ctx.restore();
    
    // Subtitle
    this.ctx.textAlign = 'center';
    this.ctx.fillStyle = '#2d3436';
    this.ctx.font = '20px "Helvetica Neue", Arial';
    if (this.currentStage === 0) {
      this.ctx.fillText('Begin your journey with aromatherapy...', this.canvas.width/2, 100);
    } else if (this.currentStage === 1) {
      this.ctx.fillText('Choose your perfect treatment...', this.canvas.width/2, 100);
    } else if (this.currentStage === 2) {
      this.ctx.fillText('Complete your experience...', this.canvas.width/2, 100);
    }
    
    if (this.isMobile) {
      const translateX = this.isDragging ? this.currentX - this.touchStartX : 0;
      
      options.forEach((option, index) => {
        const centerX = this.canvas.width / 2;
        const baseX = centerX - (this.mobileCardWidth / 2) + (index - this.currentCardIndex) * (this.mobileCardWidth + this.mobileCardSpacing);
        const x = baseX + translateX;
        
        // Only draw if card is visible in viewport
        if (x > -this.mobileCardWidth && x < this.canvas.width + this.mobileCardWidth) {
          const y = (this.canvas.height - this.mobileCardHeight) / 2;
          
          // Calculate scale based on distance from center
          const distanceFromCenter = Math.abs(x - centerX);
          const scale = Math.max(0.85, 1 - (distanceFromCenter / this.canvas.width) * 0.25);
          const opacity = Math.max(0.7, 1 - (distanceFromCenter / this.canvas.width) * 0.4);
          
          this.drawOption(option, index, x, y, true, scale, opacity);
        }
      });
      
      this.drawPaginationDots(options.length);
    } else {
      // Existing desktop layout
      options.forEach((option, index) => {
        this.drawOption(option, index);
      });
    }
  }

  drawPaginationDots(totalDots) {
    const dotRadius = 4;
    const dotSpacing = 20;
    const totalWidth = (totalDots - 1) * dotSpacing;
    let startX = (this.canvas.width - totalWidth) / 2;
    const y = this.canvas.height - 40;

    for (let i = 0; i < totalDots; i++) {
      this.ctx.beginPath();
      this.ctx.arc(startX + (i * dotSpacing), y, dotRadius, 0, Math.PI * 2);
      this.ctx.fillStyle = i === this.currentCardIndex ? '#2d3436' : '#d1d1d1';
      this.ctx.fill();
    }
  }

  drawOption(option, index, mobileX = null, mobileY = null, isMobile = false, scale = 1, opacity = 1) {
    if (isMobile) {
      const cardWidth = this.mobileCardWidth * scale;
      const cardHeight = this.mobileCardHeight * scale;
      
      this.ctx.save();
      this.ctx.translate(mobileX + cardWidth / 2, mobileY + cardHeight / 2);
      this.ctx.scale(scale, scale);
      this.ctx.globalAlpha = opacity;
      
      // Draw card background
      this.ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
      this.ctx.shadowBlur = 15;
      this.ctx.shadowOffsetY = 5;
      
      this.roundRect(-cardWidth/2, -cardHeight/2, cardWidth, cardHeight, 15);
      this.ctx.fillStyle = '#ffffff';
      this.ctx.fill();
      
      // Reset shadow for content
      this.ctx.shadowColor = 'transparent';
      
      // Draw image
      if (this.images && this.images[option.id]?.complete) {
        const imageWidth = cardWidth * 0.8;
        const imageHeight = imageWidth;
        const imageX = -imageWidth/2;
        const imageY = -cardHeight/2 + cardHeight * 0.1;
        
        this.ctx.save();
        this.roundRect(imageX, imageY, imageWidth, imageHeight, 10);
        this.ctx.clip();
        this.ctx.drawImage(this.images[option.id], imageX, imageY, imageWidth, imageHeight);
        this.ctx.restore();
      }
      
      // Draw text content scaled appropriately with more spacing
      this.ctx.textAlign = 'center';
      this.ctx.fillStyle = '#2d3436';
      
      // Title - moved down by adjusting the multiplier from 0.7 to 0.75
      this.ctx.font = `bold ${cardWidth * 0.08}px "Helvetica Neue", Arial`;
      this.ctx.fillText(option.name, 0, -cardHeight/2 + cardHeight * 0.75);
      
      // Description - moved down by adjusting the multiplier from 0.8 to 0.85
      this.ctx.font = `${cardWidth * 0.05}px "Helvetica Neue", Arial`;
      this.wrapText(option.description, 0, -cardHeight/2 + cardHeight * 0.85, cardWidth * 0.8);
      
      this.ctx.restore();
    } else {
      // Desktop layout
      const x = (this.canvas.width / 4) * (index + 1);
      const y = this.canvas.height / 2;
      
      // Adjust card size for desktop
      const cardWidth = 160;
      const cardHeight = 240;
      
      this.ctx.save();
      
      // Calculate hover and selection animations
      const hoverScale = this.isHovered(x, y) ? 1.1 : 1;
      const selectionScale = option === this.selectedOption ? 1.2 : 1;
      const finalScale = hoverScale * selectionScale;
      
      // Apply transformations
      this.ctx.translate(x, y);
      this.ctx.scale(finalScale, finalScale);
      
      // Draw card background
      this.ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
      this.ctx.shadowBlur = 15;
      this.ctx.shadowOffsetY = 5;
      
      this.roundRect(-cardWidth/2, -cardHeight/2, cardWidth, cardHeight, 15);
      this.ctx.fillStyle = '#ffffff';
      this.ctx.fill();
      
      // Reset shadow for content
      this.ctx.shadowColor = 'transparent';
      
      // Draw image
      if (this.images && this.images[option.id]?.complete) {
        const imageWidth = cardWidth * 0.8;
        const imageHeight = imageWidth;
        const imageX = -imageWidth/2;
        const imageY = -cardHeight/2 + 20;
        
        this.ctx.save();
        this.roundRect(imageX, imageY, imageWidth, imageHeight, 10);
        this.ctx.clip();
        this.ctx.drawImage(this.images[option.id], imageX, imageY, imageWidth, imageHeight);
        this.ctx.restore();
      }
      
      // Draw text with adjusted spacing
      this.ctx.textAlign = 'center';
      this.ctx.fillStyle = '#2d3436';
      
      // Calculate text positions based on image position and size
      const imageBottom = -cardHeight/2 + 20 + (cardWidth * 0.8); // imageY + imageHeight
      const titleY = imageBottom + 30; // 30px padding after image
      const descriptionY = titleY + 25; // 25px padding after title
      
      // Title
      this.ctx.font = 'bold 16px "Helvetica Neue", Arial';
      this.ctx.fillText(option.name, 0, titleY);
      
      // Description
      this.ctx.font = '14px "Helvetica Neue", Arial';
      this.wrapText(option.description, 0, descriptionY, cardWidth - 20);
      
      this.ctx.restore();
    }
  }

  drawParticles(x, y, color) {
    // Add floating particles around selected option
    const time = performance.now() / 1000;
    for (let i = 0; i < 12; i++) {
      const angle = (i / 12) * Math.PI * 2 + time;
      const radius = 100 + Math.sin(time * 2 + i) * 20;
      const px = x + Math.cos(angle) * radius;
      const py = y + Math.sin(angle) * radius;
      
      this.ctx.beginPath();
      this.ctx.fillStyle = color + '80'; // Add transparency
      this.ctx.arc(px, py, 4, 0, Math.PI * 2);
      this.ctx.fill();
    }
  }

  roundRect(x, y, width, height, radius) {
    this.ctx.beginPath();
    this.ctx.moveTo(x + radius, y);
    this.ctx.lineTo(x + width - radius, y);
    this.ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    this.ctx.lineTo(x + width, y + height - radius);
    this.ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    this.ctx.lineTo(x + radius, y + height);
    this.ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    this.ctx.lineTo(x, y + radius);
    this.ctx.quadraticCurveTo(x, y, x + radius, y);
    this.ctx.closePath();
  }

  wrapText(text, x, y, maxWidth) {
    const words = text.split(' ');
    let line = '';
    let lineHeight = 20;

    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = this.ctx.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth && n > 0) {
        this.ctx.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
      } else {
        line = testLine;
      }
    }
    this.ctx.fillText(line, x, y);
  }

  getCurrentStageOptions() {
    switch (this.currentStage) {
      case 0: return this.options.scents;
      case 1: return this.options.treatments;
      case 2: return this.options.drinks;
      default: return [];
    }
  }

  getCurrentStageType() {
    switch (this.currentStage) {
      case 0: return 'scents';
      case 1: return 'treatments';
      case 2: return 'drinks';
      default: return 'background';
    }
  }

  getStageTitle() {
    switch (this.currentStage) {
      case 0: return 'Choose Your Relaxing Scent';
      case 1: return 'Select Your Treatment';
      case 2: return 'Pick Your Refreshment';
      default: return '';
    }
  }

  onAction(x, y) {
    if (this.gameState !== 'playing') return;
    
    const selectedOption = this.getSelectedOption(x, y);
    
    if (selectedOption) {
      // Store current selection before making choice
      const currentSelection = selectedOption;
      
      // Small delay to ensure the selection is visible
      setTimeout(() => {
        this.makeChoice(currentSelection);
      }, 100);
    }
  }

  getSelectedOption(x, y) {
    const options = this.getCurrentStageOptions();
    if (this.isMobile) {
      const centerX = this.canvas.width / 2;
      const centerY = this.canvas.height / 2;
      
      if (x >= centerX - this.mobileCardWidth/2 && 
          x <= centerX + this.mobileCardWidth/2 &&
          y >= centerY - this.mobileCardHeight/2 && 
          y <= centerY + this.mobileCardHeight/2) {
        return options[this.currentCardIndex];
      }
      return null;
    } else {
      // Existing desktop hit detection
      return options.find((_, index) => {
        const cardX = (this.canvas.width / 4) * (index + 1);
        const cardY = this.canvas.height / 2;
        const hitboxSize = 100;
        return (
          x >= cardX - hitboxSize &&
          x <= cardX + hitboxSize &&
          y >= cardY - hitboxSize &&
          y <= cardY + hitboxSize
        );
      });
    }
  }

  makeChoice(option) {
    this.selectedOption = option;
    
    // Animate selection
    const animate = () => {
      if (this.transitionProgress < 1) {
        this.transitionProgress += 0.05;
        requestAnimationFrame(animate);
      } else {
        // Store the choice and handle stage progression
        switch (this.currentStage) {
          case 0:
            this.choices.scent = option;
            this.currentStage++;
            break;
          case 1:
            this.choices.treatment = option;
            this.currentStage++;
            break;
          case 2:
            this.choices.drink = option;
            this.startExperienceVisualization();
            break;
          default:
            console.log('Unknown stage:', this.currentStage);
            break;
        }
        
        this.transitionProgress = 0;
        this.selectedOption = null;
      }
    };
    
    requestAnimationFrame(animate);
  }

  startExperienceVisualization() {
    console.log('Starting visualization');
    this.gameState = 'visualizing';
    this.visualizationProgress = 0;
    
    // Create particles based on scent choice
    this.particles = [];
    const particleColors = {
      lavender: ['#E6E6FA', '#9370DB'],
      eucalyptus: ['#98FF98', '#90EE90'],
      vanilla: ['#FFFDD0', '#F5DEB3']
    };
    
    // Add ambient particles
    for (let i = 0; i < 50; i++) {
      this.particles.push({
        x: Math.random() * this.canvas.width,
        y: Math.random() * this.canvas.height,
        size: Math.random() * 4 + 2,
        speed: Math.random() * 1 + 0.5,
        color: particleColors[this.choices.scent.id][Math.floor(Math.random() * 2)],
        angle: Math.random() * Math.PI * 2
      });
    }
    
    // Start the animation loop
    this.animate();
  }

  animate() {
    if (this.gameState !== 'visualizing') return;
    
    this.drawExperienceVisualization();
    requestAnimationFrame(() => this.animate());
  }

  drawExperienceVisualization() {
    // Clear canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    
    // Draw background
    const gradient = this.ctx.createLinearGradient(0, 0, 0, this.canvas.height);
    gradient.addColorStop(0, '#f6d5f7');
    gradient.addColorStop(1, '#fbe9d7');
    this.ctx.fillStyle = gradient;
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    
    // Update and draw particles
    this.particles.forEach(particle => {
      particle.x += Math.cos(particle.angle) * particle.speed;
      particle.y += Math.sin(particle.angle) * particle.speed;
      
      // Wrap particles around screen
      if (particle.x < 0) particle.x = this.canvas.width;
      if (particle.x > this.canvas.width) particle.x = 0;
      if (particle.y < 0) particle.y = this.canvas.height;
      if (particle.y > this.canvas.height) particle.y = 0;
      
      // Draw particle with glow
      this.ctx.save();
      this.ctx.shadowColor = particle.color;
      this.ctx.shadowBlur = 15;
      this.ctx.beginPath();
      this.ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      this.ctx.fillStyle = particle.color;
      this.ctx.fill();
      this.ctx.restore();
    });
    
    // Draw messages and countdown
    this.ctx.textAlign = 'center';
    this.visualizationProgress += 0.016; // Approximately 60 FPS
    
    // Fade in the content
    const fadeInDuration = 1; // 1 second fade in
    const fadeInAlpha = Math.min(1, this.visualizationProgress / fadeInDuration);
    this.ctx.globalAlpha = fadeInAlpha;
    
    // Draw selection summary
    this.ctx.fillStyle = '#2d3436';
    this.ctx.font = 'bold 28px "Helvetica Neue", Arial';
    this.ctx.fillText('Your Perfect Spa Day', this.canvas.width/2, this.canvas.height/3);
    
    this.ctx.font = '20px "Helvetica Neue", Arial';
    this.ctx.fillText(
      `Relax with the soothing scent of ${this.choices.scent.name}`,
      this.canvas.width/2,
      this.canvas.height/3 + 50
    );
    
    this.ctx.fillText(
      `Enjoy a rejuvenating ${this.choices.treatment.name}`,
      this.canvas.width/2,
      this.canvas.height/3 + 90
    );
    
    this.ctx.fillText(
      `Complete your experience with ${this.choices.drink.name}`,
      this.canvas.width/2,
      this.canvas.height/3 + 130
    );
    
    // Start countdown after fade-in complete
    if (fadeInAlpha >= 1) {
      if (!this.countdownStarted) {
        this.countdownStarted = true;
        this.countdownStartTime = Date.now();
      }
      
      const secondsTotal = 10;
      const secondsElapsed = (Date.now() - this.countdownStartTime) / 1000;
      const secondsLeft = Math.ceil(secondsTotal - secondsElapsed);
      
      if (secondsLeft >= 0) {
        // Get device pixel ratio
        const dpr = window.devicePixelRatio || 1;
        
        // Calculate progress
        const progress = Math.min(1, secondsElapsed / secondsTotal);
        
        // Keep circle small
        const radius = Math.min(20, this.canvas.width * 0.04);
        
        // Calculate positions with much more separation
        const circleY = this.canvas.height * 0.8; // Move circle slightly lower
        const textY = circleY - (radius * 6); // Increase spacing to 6x radius
        
        // Draw countdown text
        const text = `${secondsLeft} seconds of peace...`;
        this.ctx.font = 'bold 24px "Helvetica Neue", Arial';
        
        // Draw text well above circle
        this.ctx.fillText(text, this.canvas.width/2, textY);
        
        const centerX = this.canvas.width/2;
        const centerY = circleY;
        
        // Save context state
        this.ctx.save();
        
        // Ensure perfect circle with 1:1 aspect ratio
        this.ctx.setTransform(dpr, 0, 0, dpr, 0, 0);
        
        // Background circle
        this.ctx.beginPath();
        this.ctx.arc(centerX/dpr, centerY/dpr, radius, 0, Math.PI * 2);
        this.ctx.strokeStyle = 'rgba(45, 52, 54, 0.2)';
        this.ctx.lineWidth = 3;
        this.ctx.stroke();
        
        // Progress circle
        this.ctx.beginPath();
        this.ctx.arc(centerX/dpr, centerY/dpr, radius, -Math.PI/2, (-Math.PI/2) + (Math.PI * 2 * progress));
        this.ctx.strokeStyle = '#2d3436';
        this.ctx.stroke();
        
        // Restore context state
        this.ctx.restore();
      } else {
        // Transition to win state after countdown
        this.gameState = 'won';
        this.onComplete('won', 'Happy Birthday!');
      }
    }
  }

  drawCountdown() {
    const secondsLeft = Math.ceil(12 - this.visualizationProgress);
    
    this.ctx.fillStyle = '#2d3436';
    this.ctx.font = 'bold 24px "Helvetica Neue", Arial';
    this.ctx.fillText(`${secondsLeft} seconds of peace...`, this.canvas.width/2, this.canvas.height/2);
    
    // Draw a circular progress indicator
    const progress = (this.visualizationProgress - 2) / 10; // 0 to 1 over 10 seconds
    const radius = 40;
    const centerX = this.canvas.width/2;
    const centerY = this.canvas.height/2 + 50;
    
    this.ctx.beginPath();
    this.ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
    this.ctx.strokeStyle = 'rgba(45, 52, 54, 0.2)';
    this.ctx.lineWidth = 5;
    this.ctx.stroke();
    
    this.ctx.beginPath();
    this.ctx.arc(centerX, centerY, radius, -Math.PI/2, (-Math.PI/2) + (Math.PI * 2 * progress));
    this.ctx.strokeStyle = '#2d3436';
    this.ctx.stroke();
  }

  drawFinalMessage() {
    this.ctx.fillStyle = '#2d3436';
    this.ctx.font = 'bold 28px "Helvetica Neue", Arial';
    this.ctx.fillText('This is just a taste...', this.canvas.width/2, this.canvas.height/2 - 20);
    
    this.ctx.font = '24px "Helvetica Neue", Arial';
    this.ctx.fillText("It's time to book this for real!", this.canvas.width/2, this.canvas.height/2 + 20);
  }

  drawSelectionSummary() {
    this.ctx.fillStyle = '#2d3436';
    this.ctx.font = 'bold 28px "Helvetica Neue", Arial';
    this.ctx.fillText('Your Perfect Spa Day', this.canvas.width/2, this.canvas.height/3);
    
    this.ctx.font = '20px "Helvetica Neue", Arial';
    this.ctx.fillText(
      `Relax with the soothing scent of ${this.choices.scent.name}`,
      this.canvas.width/2,
      this.canvas.height/3 + 50
    );
    
    this.ctx.fillText(
      `Enjoy a rejuvenating ${this.choices.treatment.name}`,
      this.canvas.width/2,
      this.canvas.height/3 + 90
    );
    
    this.ctx.fillText(
      `Complete your experience with ${this.choices.drink.name}`,
      this.canvas.width/2,
      this.canvas.height/3 + 130
    );
  }

  cleanup() {
    this.gameState = 'stopped';
    window.removeEventListener('resize', () => this.resizeCanvas());
    this.canvas.removeEventListener('mousemove', this.handleMouseMove);
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    this.canvas.removeEventListener('touchstart', this.handleTouchStart);
    this.canvas.removeEventListener('touchmove', this.handleTouchMove);
    this.canvas.removeEventListener('touchend', this.handleTouchEnd);
  }

  startGameLoop() {
    const gameLoop = () => {
      if (this.gameState === 'playing') {
        this.update();
        this.draw();
        this.animationFrameId = requestAnimationFrame(gameLoop);
      }
    };
    
    // Start the game loop
    this.animationFrameId = requestAnimationFrame(gameLoop);
  }

  // Add this method to help debug image loading
  verifyImages() {
    console.log('Verifying images...');
    for (const [key, img] of Object.entries(this.images)) {
      console.log(`${key}:`, {
        loaded: img?.complete,
        src: img?.src,
        width: img?.width,
        height: img?.height
      });
    }
  }

  handleTouchStart(event) {
    if (!this.isMobile) return;
    
    event.preventDefault();
    
    this.touchStartX = event.touches[0].clientX;
    this.touchStartY = event.touches[0].clientY;
    this.currentX = this.touchStartX;
    this.isDragging = false; // Start as false until we detect a drag
    this.swipeStartTime = Date.now();
    this.isTap = true; // Start assuming it's a tap
  }

  handleTouchMove(event) {
    if (!this.isMobile) return;
    
    event.preventDefault();
    
    const deltaX = Math.abs(event.touches[0].clientX - this.touchStartX);
    const deltaY = Math.abs(event.touches[0].clientY - this.touchStartY);
    
    // If movement is more than 10px, it's a drag, not a tap
    if (deltaX > 10 || deltaY > 10) {
      this.isTap = false;
      this.isDragging = true;
    }
    
    if (this.isDragging) {
      this.currentX = event.touches[0].clientX;
      
      // If vertical scrolling is detected, stop handling the swipe
      if (deltaY > deltaX) {
        this.isDragging = false;
        return;
      }
    }
  }

  handleTouchEnd(event) {
    if (!this.isMobile) return;
    
    const deltaX = this.currentX - this.touchStartX;
    const options = this.getCurrentStageOptions();
    const swipeTime = Date.now() - this.swipeStartTime;
    const swipeVelocity = Math.abs(deltaX) / swipeTime;
    
    if (this.isTap) {
      // Handle tap selection for the current card
      const currentOption = options[this.currentCardIndex];
      if (currentOption) {
        this.makeChoice(currentOption);
      }
    } else if (this.isDragging) {
      // Handle swipe
      const isSignificantSwipe = Math.abs(deltaX) > this.mobileCardWidth * 0.2 || swipeVelocity > 0.5;
      
      if (isSignificantSwipe) {
        if (deltaX > 0 && this.currentCardIndex > 0) {
          this.currentCardIndex--;
        } else if (deltaX < 0 && this.currentCardIndex < options.length - 1) {
          this.currentCardIndex++;
        }
      }
    }
    
    this.isDragging = false;
    this.isTap = false;
    
    // Trigger a redraw
    this.draw();
  }

  easeOutExpo(x) {
    return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
  }

  // Update the card drawing to be more consistent
  drawCard(option, x, y) {
    const cardWidth = 160;
    const cardHeight = 240;
    
    // Draw card background
    this.ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
    this.ctx.shadowBlur = 15;
    this.ctx.shadowOffsetY = 5;
    
    this.roundRect(x, y, cardWidth, cardHeight, 15);
    this.ctx.fillStyle = '#ffffff';
    this.ctx.fill();
    
    // Reset shadow for content
    this.ctx.shadowColor = 'transparent';
    
    // Draw image
    if (this.images && this.images[option.id]?.complete) {
      const imageWidth = 120;
      const imageHeight = 120;
      const imageX = x + (cardWidth - imageWidth) / 2;
      const imageY = y + 20;
      
      this.ctx.save();
      this.roundRect(imageX, imageY, imageWidth, imageHeight, 10);
      this.ctx.clip();
      this.ctx.drawImage(this.images[option.id], imageX, imageY, imageWidth, imageHeight);
      this.ctx.restore();
    }
    
    // Draw text
    this.ctx.textAlign = 'center';
    this.ctx.fillStyle = '#2d3436';
    
    // Title
    this.ctx.font = 'bold 20px "Helvetica Neue", Arial';
    this.ctx.fillText(option.name, x + cardWidth/2, y + 160);
    
    // Description
    this.ctx.font = '14px "Helvetica Neue", Arial';
    const words = option.description.split(' ');
    let line = '';
    let lineY = y + 190;
    
    words.forEach(word => {
      const testLine = line + word + ' ';
      if (this.ctx.measureText(testLine).width > cardWidth - 40) {
        this.ctx.fillText(line.trim(), x + cardWidth/2, lineY);
        line = word + ' ';
        lineY += 20;
      } else {
        line = testLine;
      }
    });
    this.ctx.fillText(line.trim(), x + cardWidth/2, lineY);
  }
}

const SpaExperience = ({ giftCard, onComplete }) => {
  const canvasRef = useRef(null);
  const gameInstanceRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [gameStatus, setGameStatus] = useState('playing');

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Set initial canvas size
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    gameInstanceRef.current = new SpaGameLogic(canvas, giftCard, handleGameComplete);
    
    const initGame = async () => {
      await gameInstanceRef.current.init();
      setIsVisible(true);
    };
    initGame();

    return () => {
      if (gameInstanceRef.current) {
        gameInstanceRef.current.cleanup();
      }
    };
  }, [giftCard]);

  const handleGameComplete = (status) => {
    setGameStatus(status);
  };

  const handleReplay = () => {
    if (gameInstanceRef.current) {
      gameInstanceRef.current.restart();
      setGameStatus('playing');
    }
  };

  const handleProceed = () => {
    setIsVisible(false);
    setTimeout(() => onComplete('skipped'), 500);
  };

  return (
    <motion.div 
      className="game-container"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
      transition={{ duration: 0.5 }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        touchAction: 'none' // Prevent default touch behaviors
      }}
    >
      <canvas
        ref={canvasRef}
        className="game-canvas"
        style={{
          width: '100%',
          height: '100%',
          display: 'block',
          touchAction: 'none' // Prevent default touch behaviors
        }}
        onClick={(e) => {
          if (gameInstanceRef.current && gameStatus === 'playing') {
            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            gameInstanceRef.current.onAction(x, y);
          }
        }}
      />
      {gameStatus === 'won' && (
        <ExperienceWonScreen
          onReplay={handleReplay}
          onProceed={handleProceed}
          theme="spa"
        />
      )}
    </motion.div>
  );
};

export default SpaExperience; 