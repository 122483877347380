import React, { useState, useEffect } from 'react';
import './App.css'; // Keep global styles here
import './globals.css'; // Other global styles
import TemplateLoader from './components/TemplateLoader';
import GiftCardPresentation from './components/GiftCardPresentation';
import CardNotFound from './components/CardNotFound';
import LoadingScreen from './components/LoadingScreen';
import Header from './components/Header';
import GameOverScreen from './components/GameOverScreen';
import ExperienceWonScreen from './components/ExperienceWonScreen';

function App() {
  const [currentStep, setCurrentStep] = useState('game');
  const [gameOutcome, setGameOutcome] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [giftCard, setGiftCard] = useState(null);

  useEffect(() => {
    const fetchGiftCard = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const giftCardId = urlParams.get('id');
        const giftCardSecret = urlParams.get('secret');

        if (!giftCardId || !giftCardSecret) {
          throw new Error('Both cardId and secret are required.');
        }

        const response = await fetch(
          `https://cardventure-delivery.joshua-odonoghue.workers.dev/?id=${giftCardId}&secret=${giftCardSecret}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch gift card.');
        }

        const data = await response.json();
        const mappedGiftCard = {
          id: data.id,
          recipient_name: data.recipient_name,
          recipient_email: data.recipient_email,
          recipient_phone: data.recipient_phone,
          amount: data.amount,
          cardNumber: data.card_number,
          from_name: data.from_name,
          from_email: data.from_email,
          custom_greeting_message: data.custom_greeting_message,
          template_name: data.template_name,
          experience_type: data.experience_type || 'holiday',
          logoUrl: data.logo_url || '/Logo_Rectangle.png',
          defaultColorScheme: data.color_scheme || 'default',
          external_download_url: data.external_download_url,
          story: {
            "cover": {
              "title": "The Night Before Christmas",
              "author": "Clement Clarke Moore",
              "image": "https://cdn.shopify.com/s/files/1/0736/4850/5106/files/BookTitle.jpg?v=1727491202"
            },
            "pages": [
              {
                "content": "Twas the night before Christmas, when all through the house\nNot a creature was stirring, not even a mouse;\nThe stockings were hung by the chimney with care,\nIn hopes that St. Nicholas soon would be there;",
                "image": "https://cdn.shopify.com/s/files/1/0736/4850/5106/files/Gemini_Generated_Image_ro6obqro6obqro6o.jpg?v=1727491427t"
              },
              {
                "content": "The children were nestled all snug in their beds,\nWhile visions of sugar-plums danced in their heads;\nAnd mamma in her 'kerchief, and I in my cap,\nHad just settled down for a long winter's nap,",
                "image": "https://image-generator.ai/sleeping-children-christmas-eve"
              },
              {
                "content": "When out on the lawn there arose such a clatter,\nI sprang from the bed to see what was the matter.\nAway to the window I flew like a flash,\nTore open the shutters and threw up the sash.",
                "image": "https://image-generator.ai/man-looking-out-window-night"
              },
              {
                "content": "The moon on the breast of the new-fallen snow\nGave the lustre of mid-day to objects below,\nWhen, what to my wondering eyes should appear,\nBut a miniature sleigh, and eight tiny reindeer,",
                "image": "https://image-generator.ai/santa-sleigh-reindeer-night-sky"
              },
              {
                "content": "With a little old driver, so lively and quick,\nI knew in a moment it must be St. Nick.\nMore rapid than eagles his coursers they came,\nAnd he whistled, and shouted, and called them by name;",
                "image": "https://image-generator.ai/santa-calling-reindeer-names"
              },
              {
                "content": "\"Now, Dasher! now, Dancer! now, Prancer and Vixen!\nOn, Comet! on Cupid! on, Donder and Blitzen!\nTo the top of the porch! to the top of the wall!\nNow dash away! dash away! dash away all!\"",
                "image": "https://image-generator.ai/reindeer-flying-over-rooftops"
              },
              {
                "content": "As dry leaves that before the wild hurricane fly,\nWhen they meet with an obstacle, mount to the sky,\nSo up to the house-top the coursers they flew,\nWith the sleigh full of toys, and St. Nicholas too.",
                "image": "https://image-generator.ai/santa-sleigh-landing-on-roof"
              },
              {
                "content": "And then, in a twinkling, I heard on the roof\nThe prancing and pawing of each little hoof.\nAs I drew in my hand, and was turning around,\nDown the chimney St. Nicholas came with a bound.",
                "image": "https://image-generator.ai/santa-coming-down-chimney"
              },
              {
                "content": "He was dressed all in fur, from his head to his foot,\nAnd his clothes were all tarnished with ashes and soot;\nA bundle of toys he had flung on his back,\nAnd he looked like a peddler just opening his pack.",
                "image": "https://image-generator.ai/santa-with-toy-sack"
              },
              {
                "content": "His eyes -- how they twinkled! his dimples how merry!\nHis cheeks were like roses, his nose like a cherry!\nHis droll little mouth was drawn up like a bow,\nAnd the beard of his chin was as white as the snow;",
                "image": "https://image-generator.ai/santa-close-up-portrait"
              },
              {
                "content": "The stump of a pipe he held tight in his teeth,\nAnd the smoke it encircled his head like a wreath;\nHe had a broad face and a little round belly,\nThat shook, when he laughed like a bowlful of jelly.",
                "image": "https://image-generator.ai/jolly-santa-laughing"
              },
              {
                "content": "He was chubby and plump, a right jolly old elf,\nAnd I laughed when I saw him, in spite of myself;\nA wink of his eye and a twist of his head,\nSoon gave me to know I had nothing to dread;",
                "image": "https://image-generator.ai/santa-winking"
              },
              {
                "content": "He spoke not a word, but went straight to his work,\nAnd filled all the stockings; then turned with a jerk,\nAnd laying his finger aside of his nose,\nAnd giving a nod, up the chimney he rose;",
                "image": "https://image-generator.ai/santa-filling-stockings"
              },
              {
                "content": "He sprang to his sleigh, to his team gave a whistle,\nAnd away they all flew like the down of a thistle.\nBut I heard him exclaim, ere he drove out of sight,\n\"Happy Christmas to all, and to all a good-night!\"",
                "image": "https://image-generator.ai/santa-flying-away-christmas-night"
              }
            ]
          }
        };

        setGiftCard(mappedGiftCard);
        setLoading(false);
        setCurrentStep('game');
      } catch (err) {
        console.error(err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchGiftCard();
  }, []);

  const handleComplete = (result) => {
    console.log('Game Complete with result:', result);
    if (result === 'won') {
      if (giftCard.experience_type === 'spa') {
        setCurrentStep('presentation');
      } else {
        setGameOutcome('won');
        setCurrentStep('gameWon');
      }
    } else if (result === 'lost') {
      setGameOutcome('lost');
      setCurrentStep('gameOver');
    } else if (result === 'skipped') {
      setCurrentStep('presentation');
    }
  };

  const handleSkip = () => {
    setCurrentStep('presentation');
  };

  const handleViewGiftCard = () => {
    setCurrentStep('presentation');
  };

  const handleReplayGame = () => {
    setGameOutcome(null);
    setCurrentStep('game');
  };

  if (loading) return <LoadingScreen />;
  if (error) return <CardNotFound message={error} />;

  return (
    <div className="App flex flex-col min-h-screen">
      <Header
        onSkip={currentStep === 'game' ? handleSkip : null}
        onViewGiftCard={currentStep === 'presentation' ? handleViewGiftCard : null}
        onReplayGame={handleReplayGame}
        showSkipButton={currentStep === 'game' && gameOutcome !== 'lost'}
        showReplayButton={currentStep === 'gameOver' || currentStep === 'gameWon'}
        showBackButton={currentStep === 'presentation'}
        className="h-16"
      />
      <main className="flex-grow">
        {currentStep === 'game' && giftCard && (
          <TemplateLoader giftCard={giftCard} onComplete={handleComplete} />
        )}
        {currentStep === 'gameOver' && (
          <GameOverScreen onReplay={handleReplayGame} onSkip={handleSkip} />
        )}
        {currentStep === 'gameWon' && giftCard.experience_type !== 'spa' && (
          <ExperienceWonScreen 
            onReplay={handleReplayGame} 
            onProceed={handleViewGiftCard}
            theme={giftCard.experience_type || 'holiday'}
          />
        )}
        {currentStep === 'presentation' && giftCard && (
          <GiftCardPresentation giftCard={giftCard} onBackToGame={handleReplayGame} />
        )}
      </main>
    </div>
  );
}

export default App;