import React from 'react'
import PropTypes from 'prop-types'
import experienceRegistry from '../experiences/experienceRegistry'

const TemplateLoader = ({ giftCard, onComplete }) => {
  const ExperienceComponent = experienceRegistry[giftCard.template_name]

  if (ExperienceComponent) {
    return <ExperienceComponent giftCard={giftCard} onComplete={onComplete} />
  }

  console.error(`No matching template found for template_name: ${giftCard.template_name}`)
  return null
}

TemplateLoader.propTypes = {
  giftCard: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default TemplateLoader