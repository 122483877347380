import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import Confetti from 'react-confetti';
import useWindowSize from '../hooks/useWindowSize';
import './ExperienceWonScreen.css';

const themes = {
  holiday: {
    title: "Congratulations!",
    description: "You've reached the North Pole!",
    titleColor: "#28a745",
    primaryButton: {
      text: "Try Again",
      className: "bg-green-500 hover:bg-green-600 text-white"
    },
    secondaryButton: {
      text: "View Gift Card",
      className: "bg-red-500 hover:bg-red-600 text-white"
    },
    particles: {
      numberOfPieces: 200,
      gravity: 0.5,
      colors: ['#ff0000', '#ffffff', '#00ff00', '#ffffff'],
      drawShape: null
    }
  },
  spa: {
    title: "Hope you enjoyed yourself!",
    description: "We know that certainly wasn't long enough but don't fear, that was just a taste! Your spa day awaits. Click below to reveal your gift card.",
    titleColor: "#FF69B4",
    primaryButton: {
      text: "Start Over",
      className: "bg-pink-100 hover:bg-pink-200 text-pink-800"
    },
    secondaryButton: {
      text: "View Gift Card",
      className: "bg-pink-500 hover:bg-pink-600 text-white"
    },
    particles: {
      numberOfPieces: 50,
      gravity: 0.1,
      colors: ['#FFB7C5', '#FF69B4', '#87CEEB', '#E6E6FA'],
      drawShape: (ctx) => {
        const random = Math.random();
        if (random > 0.5) {
          // Draw petal
          ctx.beginPath();
          ctx.moveTo(0, 0);
          ctx.bezierCurveTo(5, 5, 10, 5, 10, 0);
          ctx.bezierCurveTo(10, -5, 5, -5, 0, 0);
          ctx.fill();
        } else {
          // Draw bubble
          ctx.beginPath();
          ctx.arc(0, 0, 5, 0, 2 * Math.PI);
          ctx.fill();
        }
      }
    }
  },
  default: {
    title: "Congratulations!",
    description: "Your gift card awaits!",
    titleColor: "#4A90E2",
    primaryButton: {
      text: "Try Again",
      className: "bg-blue-500 hover:bg-blue-600 text-white"
    },
    secondaryButton: {
      text: "View Gift Card",
      className: "bg-green-500 hover:bg-green-600 text-white"
    },
    particles: {
      numberOfPieces: 100,
      gravity: 0.25,
      colors: ['#4A90E2', '#FFD700', '#50E3C2', '#FF6B6B'],
      drawShape: null
    }
  }
};

const ExperienceWonScreen = ({ onReplay, onProceed, theme = 'holiday' }) => {
  const { width, height } = useWindowSize();

  const getThemeConfig = () => {
    switch (theme) {
      case 'holiday':
        return themes.holiday;
      case 'spa':
        return themes.spa;
      default:
        return themes.default;
    }
  };

  const currentTheme = getThemeConfig();

  return (
    <div className="overlay game-won-overlay">
      <Confetti
        width={width}
        height={height}
        {...currentTheme.particles}
      />
      <div className="message-box">
        <h2 className="title" style={{ color: currentTheme.titleColor }}>
          {currentTheme.title}
        </h2>
        <p className="description">
          {currentTheme.description}
        </p>
        <div className="button-group">
          <Button 
            onClick={onReplay} 
            className={currentTheme.primaryButton.className}
          >
            {currentTheme.primaryButton.text}
          </Button>
          <Button 
            onClick={onProceed}
            className={currentTheme.secondaryButton.className}
          >
            {currentTheme.secondaryButton.text}
          </Button>
        </div>
      </div>
    </div>
  );
};

ExperienceWonScreen.propTypes = {
  onReplay: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(Object.keys(themes))
};

export default ExperienceWonScreen; 