import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import './GameOverScreen.css';

const GameOverScreen = ({ onReplay, onSkip }) => {
  return (
    <div className="overlay game-over-overlay">
      <div className="message-box">
        <h2 className="title">Not Quite!</h2>
        <p className="description">Would you like to try again?</p>
        <div className="button-group">
          <Button onClick={onReplay} variant="primary">
            Try Again
          </Button>
          <Button onClick={onSkip} variant="secondary">
            View Gift Card
          </Button>
        </div>
      </div>
    </div>
  );
};

GameOverScreen.propTypes = {
  onReplay: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
};

export default GameOverScreen;