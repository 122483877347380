import React from 'react';
import { Button } from './ui/button';
import { Pause, Play, RotateCcw, Volume2, VolumeX } from 'lucide-react';

const ReadingControls = ({ 
  isPlaying, 
  isMuted,
  onPlayPause, 
  onRestart, 
  onToggleMute 
}) => {
  return (
    <div className="flex items-center justify-center space-x-2 p-4 bg-gray-100 rounded-lg">
      <Button
        variant="outline"
        size="icon"
        onClick={onPlayPause}
        aria-label={isPlaying ? 'Pause' : 'Play'}
      >
        {isPlaying ? <Pause className="h-4 w-4" /> : <Play className="h-4 w-4" />}
      </Button>
      
      <Button
        variant="outline"
        size="icon"
        onClick={onRestart}
        aria-label="Restart"
      >
        <RotateCcw className="h-4 w-4" />
      </Button>

      <Button
        variant="outline"
        size="icon"
        onClick={onToggleMute}
        aria-label={isMuted ? 'Enable voice' : 'Disable voice'}
      >
        {isMuted ? <VolumeX className="h-4 w-4" /> : <Volume2 className="h-4 w-4" />}
      </Button>
    </div>
  );
};

export default ReadingControls; 