import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { ArrowLeftIcon } from 'lucide-react';
import './Header.css';

const Header = ({
  onSkip,
  onViewGiftCard,
  onReplayGame,
  showSkipButton,
  showReplayButton,
  showBackButton,
}) => {
  return (
    <header className="bg-white p-4 flex justify-between items-center relative header-shadow">
      <div className="flex items-center">
        <img 
          src="/Logo_Rectangle.png" 
          alt="CardVenture Logo" 
          className="h-12 w-auto"
        />
      </div>

      <div className="flex items-center space-x-2">
        {showBackButton && (
          <Button onClick={onReplayGame} variant="outline" className="flex items-center text-sm">
            <ArrowLeftIcon className="icon mr-2" />
            Back to Experience
          </Button>
        )}
        {showReplayButton && (
          <Button onClick={onReplayGame} variant="outline" className="flex items-center text-sm">
            Start Over
          </Button>
        )}
        {showSkipButton && (
          <Button onClick={onSkip} variant="outline" className="flex items-center text-sm">
            {onViewGiftCard ? 'View Gift Card' : 'Skip to Gift Card'}
          </Button>
        )}
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600"></div>
    </header>
  );
};

Header.propTypes = {
  onSkip: PropTypes.func,
  onViewGiftCard: PropTypes.bool,
  onReplayGame: PropTypes.func,
  showSkipButton: PropTypes.bool,
  showReplayButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
};

export default Header;