"use client"

import React from 'react'
import VirtualBook from '../components/VirtualBook'

const BookExperience = ({ giftCard, onComplete }) => {
  const { story } = giftCard // Accessing the story data directly

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <VirtualBook
        pages={story.pages}
        cover={story.cover}
        onComplete={onComplete}
      />
    </div>
  )
}

export default BookExperience