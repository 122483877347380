import { PollyClient, SynthesizeSpeechCommand } from "@aws-sdk/client-polly";

class TextToSpeechService {
  constructor() {
    this.pollyClient = new PollyClient({
      region: process.env.REACT_APP_AWS_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      }
    });
    this.audio = null;
    this.isPlaying = false;
    this.onHighlight = null;
    this.activeAudioController = null;
  }

  async initialize() {
    if (!process.env.REACT_APP_AWS_ACCESS_KEY_ID || 
        !process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ||
        !process.env.REACT_APP_AWS_REGION) {
      throw new Error('AWS credentials are not configured. Please check your .env file.');
    }

    try {
      const testCommand = new SynthesizeSpeechCommand({
        Text: "Test",
        OutputFormat: "mp3",
        VoiceId: "Matthew",
        Engine: "neural"
      });
      await this.pollyClient.send(testCommand);
    } catch (error) {
      console.error('Failed to initialize Text-to-Speech service:', error);
      throw error;
    }
  }

  async generateSpeech(text) {
    const command = new SynthesizeSpeechCommand({
      Engine: "neural",
      LanguageCode: "en-US",
      Text: text,
      TextType: "text",
      OutputFormat: "mp3",
      VoiceId: "Brian"
    });

    try {
      const response = await this.pollyClient.send(command);
      const audioArrayBuffer = await response.AudioStream.transformToByteArray();
      const audioBlob = new Blob([audioArrayBuffer], { type: 'audio/mpeg' });
      return audioBlob;
    } catch (error) {
      console.error('Error generating speech:', error);
      throw error;
    }
  }

  async generateSpeechPlainText(text) {
    const command = new SynthesizeSpeechCommand({
      Engine: "neural",
      LanguageCode: "en-US",
      Text: text,
      TextType: "text",
      OutputFormat: "mp3",
      VoiceId: "Brian",
      SampleRate: "24000",
      Rate: 0.85
    });

    const response = await this.pollyClient.send(command);
    const audioArrayBuffer = await response.AudioStream.transformToByteArray();
    return new Blob([audioArrayBuffer], { type: 'audio/mpeg' });
  }

  async speak(text, onHighlight) {
    this.cleanup();
    this.onHighlight = onHighlight;

    try {
      const audioBlob = await this.generateSpeech(text);
      const audioUrl = URL.createObjectURL(audioBlob);

      this.audio = new Audio(audioUrl);
      
      // Split text into words for highlighting
      const words = text.split(/\s+/);
      const estimatedDuration = await this.getAudioDuration(audioBlob);
      const timePerWord = estimatedDuration / words.length;

      this.audio.addEventListener('timeupdate', () => {
        if (this.onHighlight) {
          const currentWordIndex = Math.floor(this.audio.currentTime / timePerWord);
          if (currentWordIndex < words.length) {
            this.onHighlight(currentWordIndex);
          }
        }
      });

      this.audio.addEventListener('ended', this.handleEnded.bind(this));

      await this.audio.play();
      this.isPlaying = true;

    } catch (error) {
      console.error('Error in speak:', error);
      this.cleanup();
    }
  }

  async getAudioDuration(audioBlob) {
    return new Promise((resolve) => {
      const audio = new Audio(URL.createObjectURL(audioBlob));
      audio.addEventListener('loadedmetadata', () => {
        resolve(audio.duration);
      });
    });
  }

  handleEnded = () => {
    this.cleanup();
    if (this.onHighlight) {
      this.onHighlight(null);
    }
  }

  cleanup() {
    if (this.audio) {
      this.audio.pause();
      this.audio.removeEventListener('timeupdate', this.handleTimeUpdate);
      this.audio.removeEventListener('ended', this.handleEnded);
      if (this.audio.src) {
        URL.revokeObjectURL(this.audio.src);
      }
      this.audio = null;
    }

    this.isPlaying = false;
  }

  pause() {
    if (this.audio && this.isPlaying) {
      this.audio.pause();
      this.isPlaying = false;
    }
  }

  resume() {
    if (this.audio && !this.isPlaying) {
      this.audio.play();
      this.isPlaying = true;
    }
  }

  stop() {
    this.cleanup();
  }

  get isPaused() {
    return this.audio ? this.audio.paused : true;
  }

  get isSpeaking() {
    return this.isPlaying;
  }
}

const textToSpeechService = new TextToSpeechService();

export default textToSpeechService; 