"use client"

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { Button } from './ui/button';
import ReadingControls from './ReadingControls';
import textToSpeechService from '../services/textToSpeechService';
import PropTypes from 'prop-types'
import './VirtualBook.css';

export function VirtualBook({ pages, cover }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [highlightedWordIndex, setHighlightedWordIndex] = useState(null);
  const contentRef = useRef(null);

  // Initialize text-to-speech service
  useEffect(() => {
    textToSpeechService.initialize();
    return () => {
      textToSpeechService.cleanup();
    };
  }, []);

  // Define readCurrentPage with useCallback
  const readCurrentPage = useCallback(() => {
    if (pages[currentPage]) {
      textToSpeechService.speak(pages[currentPage]);
    }
  }, [currentPage, pages]);

  useEffect(() => {
    readCurrentPage();
  }, [readCurrentPage]);

  const handlePageChange = (newPage) => {
    console.log('Changing to page:', newPage); // Debug log
    // Stop any current audio and clear highlights
    textToSpeechService.cleanup();
    setHighlightedWordIndex(null);
    setIsPlaying(false);
    setCurrentPage(newPage);
  };

  const goToNextPage = () => {
    if (currentPage < pages.length - 1) {
      setDirection(1);
      handlePageChange(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > -1) {
      setDirection(-1);
      handlePageChange(currentPage - 1);
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      textToSpeechService.pause();
    } else {
      textToSpeechService.resume();
    }
    setIsPlaying(!isPlaying);
  };

  const handleRestart = () => {
    textToSpeechService.stop();
    readCurrentPage();
  };

  const handleToggleMute = () => {
    if (!isMuted) {
      textToSpeechService.stop();
      setIsPlaying(false);
    }
    setIsMuted(!isMuted);
  };

  const pageVariants = {
    initial: (direction) => ({
      rotateY: direction > 0 ? 90 : -90,
      x: direction > 0 ? '50%' : '-50%',
      opacity: 0,
    }),
    animate: {
      rotateY: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
      },
    },
    exit: (direction) => ({
      rotateY: direction > 0 ? -90 : 90,
      x: direction > 0 ? '-50%' : '50%',
      opacity: 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
      },
    }),
  };

  const renderCover = () => (
    <div className="h-full flex flex-col items-center justify-center bg-gradient-to-b from-amber-100 to-amber-300 p-8">
      <h1 className="text-4xl font-bold mb-4 text-center">{cover.title}</h1>
      <img
        src={cover.image}
        alt="Book Cover"
        className="w-full h-3/4 object-cover rounded-lg shadow-lg mb-4"
      />
      <p className="text-xl italic">by {cover.author}</p>
    </div>
  );

  const renderContent = (content) => {
    return (
      <div ref={contentRef} className="prose max-w-none">
        {content.split(/\s+/).map((word, index) => (
          <span
            key={index}
            className={`word-wrapper inline-block mx-1 transition-colors duration-200 ease-in-out
              ${highlightedWordIndex === index ? 'bg-yellow-200 rounded px-1' : ''}`}
          >
            {word}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className="flex items-center justify-center w-full h-screen bg-gray-900 p-4">
      <div className="relative w-full max-w-4xl h-full max-h-[90vh] flex bg-white shadow-2xl rounded-lg overflow-hidden perspective-1000">
        {currentPage >= 0 && (
          <div className="absolute top-4 right-4 z-20">
            <ReadingControls
              isPlaying={isPlaying}
              isMuted={isMuted}
              onPlayPause={handlePlayPause}
              onRestart={handleRestart}
              onToggleMute={handleToggleMute}
            />
          </div>
        )}
        <Button
          onClick={goToPreviousPage}
          disabled={currentPage === -1}
          className="absolute left-2 top-1/2 transform -translate-y-1/2 rounded-full p-2 z-10"
          variant="outline"
        >
          <ChevronLeft className="h-6 w-6" />
        </Button>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={currentPage}
            custom={direction}
            variants={pageVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="absolute inset-0 flex-grow flex flex-col p-8 overflow-auto bg-white"
            style={{ transformOrigin: direction > 0 ? 'left' : 'right' }}
          >
            {currentPage === -1 ? (
              renderCover()
            ) : (
              <>
                {pages[currentPage].image && (
                  <img
                    src={pages[currentPage].image}
                    alt={`Page ${currentPage + 1} illustration`}
                    className="w-full max-h-[40vh] object-cover mb-4 rounded"
                  />
                )}
                <div ref={contentRef} className="flex-grow overflow-auto text-lg leading-relaxed text-gray-800">
                  {currentPage >= 0 && renderContent(pages[currentPage].content)}
                </div>
                <div className="text-center text-sm text-gray-500 mt-4 pb-4">
                  Page {currentPage + 1} of {pages.length}
                </div>
              </>
            )}
          </motion.div>
        </AnimatePresence>
        <Button
          onClick={goToNextPage}
          disabled={currentPage === pages.length - 1}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 rounded-full p-2 z-10"
          variant="outline"
        >
          <ChevronRight className="h-6 w-6" />
        </Button>
      </div>
    </div>
  );
}

VirtualBook.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      image: PropTypes.string,
    })
  ).isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default VirtualBook