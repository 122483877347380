// src/experiences/experienceRegistry.js
import BookExperience from './BookExperience';
import SpaExperience from './SpaExperience';

const experienceRegistry = {
  Holiday: BookExperience,
  NightBeforeChristmasStory: BookExperience,
  Book: BookExperience,
  Spa: SpaExperience
};

export default experienceRegistry;